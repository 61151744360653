import React, { useRef, useState } from "react";
import "../vehicles.css";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { statusOptions } from "../../../../utils/constants";
import {
  createVehicleApi,
  getVehicleByIdApi,
  handleCreateVehicleApi,
} from "services/customAPI";
import Loader from "components/loader/loader";
import Select from "react-select";
import Card from "components/card";
import { Button } from "@chakra-ui/react";
import { getSpotsListVehicle } from "services/customAPI";

const Logger = (props) => {
  const {
    setIsDocuments,
  } = props;
  const firstRender = useRef(true);
  const formik = useFormikContext();
  const params = useParams();

  React.useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      if (formik.values?.documents?.length > 0) {
        console.log("docu true");
        setIsDocuments(true);
      } else {
        console.log("docu false");
        if (params.id) {
          setIsDocuments(false);
        }
      }

      console.log("documents values in logger :>> ", formik.values?.documents);
    }
  }, [formik.values?.documents]);

  // React.useEffect(() => {
  //   if (firstRender.current) {
  //     firstRender.current = false;
  //   } else {
  //     if (formik.values?.vehicleModel) {
  //       allAvailableVehiclesTypes.map((data: any) => {
  //         if (data.vehicleModel === formik.values.vehicleModel) {
  //           console.log("data.vehicleType",data.vehicleType)
  //           formik.values.vehicleMake = data.vehicleMake;
  //           formik.values.vehicleType = data.vehicleType;
  //           setVehicleMake(data.vehicleMake);
  //           setVehicleType(data.vehicleType);
  //         }
  //       });
  //     } else {
  //     }
  //   }
  // }, [formik.values?.vehicleModel]);
  return null;
};



const VehicleForm = () => {
  const [initialFormValues, setInitialFormValues] = useState({
    vehicleNumber: "",
    spotName: "",
  });
  const [paramData, setParamData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [spotName, setSpotName] = useState('');
  const [options, setOptions] = useState([
    {
      value: "",
      label: "",
    },
  ]);

  const navigate = useNavigate();
  const params = useParams(); // Correctly define the type of params
  const [allAvailableVehiclesTypes, setAllAvailableVehiclesTypes] = useState([]);

  const vehicleSchema = Yup.object().shape({
    // spotName: Yup.string()
    //   .min(2, "SpotName must be atleast two characters.")
    //   .required("Spot name is required"),
    vehicleNumber: Yup.string()
      .min(10, "Vehicle Number must be 10 digits only.")
      .max(10, "Vehicle Number must be 10 digits only.")
      .matches(
        /^[A-Za-z]{2}\d{2}[A-Za-z]{2}\d{4}$/,
        "Vehicle Number must follow the pattern: XX99XX9999"
      )
      .required("Vehicle number is required"),
  });

  const successToast = (message) => {
    // console.log("Inside successToast", message); // Add this line for debugging
    toast.success(`${message}`, {
      position: 'top-right',
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      style: { borderRadius: "15px" },
    });
  };

  const errorToast = (message) => {
    toast.error(`${message}`, {
      position: 'top-right',
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      style: { borderRadius: "15px" },
    });
  };

  const handleCreateVehicle = async (values) => {
    setIsLoading(true);
    try {
      if (params.id) {

        const result = await handleCreateVehicleApi(params.id, {
          vehicleNumber: values.vehicleNumber,
          spotName: values.spotName,
        });
        console.log("result :>> ", result);

        if (result.message) {
          successToast("Vehicle Updated Successfully");
          navigate("/admin/vehicles");
          setIsLoading(false);
        } else {
          errorToast("Something went wrong");
        }
      } else {
        console.log("valuesCreate",values)
        const result = await createVehicleApi({
          vehicleNumber: values.vehicleNumber,
          spotName: spotName,
        });
        console.log("result :>> ", result);
        if (result.message) {
          successToast("Vehicle Created Successfully");
          navigate("/admin/vehicles");
          setIsLoading(false);
        } else {
          errorToast("Something went wrong");
        }
      }
    } catch (error) {
      errorToast(error.response.data.message);
      console.log(error);
      setIsLoading(false);
    }
  };

  const getData = async (id) => {
    // console.log("get data called :>> ", id);
    setIsLoading(true);
    try {
      const res = await getVehicleByIdApi(id);
      console.log("res", res)

      console.log("res  :>> ", res);

      setInitialFormValues({
        vehicleNumber: res.data.vehicleNumber,
        spotName: res.data.spotName,
      });
      setParamData(res.data);

      setIsLoading(false);
    } catch (error) {
      errorToast(error.response?.data?.message || "Something went wrong");
      setIsLoading(false);
    }
  };

  const handleClick = () => {
    // Set the desired value to the input field
    setInitialFormValues({
      vehicleNumber: initialFormValues.vehicleNumber,
      spotName: initialFormValues.spotName,
    });
  };

  const getSpotList = async () => {
    try {
      const res = await getSpotsListVehicle();
      console.log("resSpots", res)
      setOptions(
        res.data
            .filter(option => option.vehicleNumber === '') // Filter out options without spotName
            .map(option => ({
                value: option.spotName,
                label: option.spotName,
            }))
    );
    } catch (error) {
      errorToast(error.response?.data?.message || "Something went wrong");
    }
  }

  React.useEffect(() => {
    console.log("object params.id :>> ", params.id);
    if (params.id) {
      getData(params.id);
    }
  }, [params]);

  React.useEffect(() => {
    getSpotList();
  }, [])

  return (
    <>
      {/* <Navbar flag={false} brandText="vehicleform" /> */}
      {isLoading ? (
        <Loader />
      ) : (
        <Card extra={"w-full pb-6 p-4 mt-4 pt-10"}>
          <header className="relative flex items-center justify-between ps-10">
            {params.id ? (
              <div className="text-xl font-bold text-navy-700 dark:text-white">
                Edit Vehicle
              </div>
            ) : (
              <div className="text-xl font-bold text-navy-700 dark:text-white">
                Add Vehicle
              </div>
            )}
          </header>
          <div className="p-10 pb-5 pe-20 ps-20" onClick={handleClick}>
            <Formik
              enableReinitialize={true}
              initialValues={initialFormValues}
              onSubmit={(values) => {
                handleCreateVehicle(values);
              }}
              validationSchema={vehicleSchema}
            >
              {({
                handleChange,
                setFieldValue,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Logger
                    display="hidden"
                    allAvailableVehiclesTypes={allAvailableVehiclesTypes}
                  />
                  <div className="flex justify-between">
                    <div className="mb-3 me-6 w-full">
                      <label
                        htmlFor="vehicleNumber"
                        className="input-custom-label dark:text-white"
                      >
                        Vehicle Number
                      </label>
                      <input
                        className="mt-2 h-12 w-full rounded-xl border bg-white/0 p-3 text-sm outline-none"
                        required
                        name="vehicleNumber"
                        type="text"
                        id="vehicleNumber"
                        width="90%"
                        // label="Vehicle Name"
                        placeholder="Vehicle Number"
                        onChange={handleChange}
                        onBlur={(event) => {
                          handleBlur(event);
                          // Copy the value of vehicleNumber to vehicleName
                          // if (!params.id) {
                          //   setFieldValue("vehicleName", event.target.value);
                          // }
                        }}
                        value={values?.vehicleNumber}
                        aria-describedby="exampleFormControlInputHelpInline"
                      />
                      {errors.vehicleNumber && touched.vehicleNumber ? (
                        <div className="error-input">
                          {errors.vehicleNumber}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-3 ms-6 w-full">
                      <label
                        htmlFor="spotName"
                        className="input-custom-label dark:text-white"
                      >
                        Spot Name
                      </label>
                      <Select
                        options={options}
                        id="spotName"
                        name="spotName"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setSpotName(e.value);
                          values.spotName = e.value;
                        }}
                        value={options.filter(function (option) {
                          return option.value == spotName;
                        })}
                        styles={{
                          // Fixes the overlapping problem of the component
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 9999,
                          }),
                          control: (provided) => ({
                            ...provided,
                            height: "47px", // Adjust the height as needed
                            marginTop: "8px",
                            borderRadius: "10px",
                            fontSize: "0.875rem",
                            borderColor: "#e6e6e6",
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                              ? "#f2f3f7"
                              : "white", // Change the background color here
                            color: "black", // Change the text color here
                            "&:hover": {
                              backgroundColor: "#f2f3f7", // Change the background color on hover
                            },
                          }),
                        }}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                      <div className="error-input">
                        {errors.spotName && touched.spotName
                          ? errors.spotName
                          : null}
                      </div>
                    </div>
                  </div>

                  <div className="button-save-cancel mt-3 flex justify-end">
                    <Button
                      color="dark"
                      className=" cancel-button my-2 ms-1 sm:my-0"
                      onClick={() => navigate("/admin/vehicles")}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="dark"
                      type="submit"
                      className="save-button my-2 ms-1 bg-brand-500 dark:bg-brand-400 sm:my-0"
                      onClick={() => handleSubmit}
                    >
                      Save
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Card>
      )}
    </>
  );
};
export default VehicleForm;