import React from "react";
import "./loader.css";

const Loader = ({ size = 40 }) => {
  const loaderStyle= {
    width: `${size}px`,
    height: `${size}px`,
    borderTopColor: "blue",
  };

  return (
    <div className="loader_container">
      <div className="loader" style={loaderStyle}></div>
    </div>
  );
};

export default Loader;