import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
  MdLock,
} from "react-icons/md";
import { GiMountainRoad } from "react-icons/gi";
import SpotForm from "views/admin/default/components/SpotForm";
import Vehicles from "views/admin/vehicles";
import VehicleForm from "views/admin/vehicles/vehicleform";
import Staff from "views/admin/staff";
import StaffForm from "views/admin/staff/staffForm";
import Rides from "views/admin/rides";
import { IoCarSportSharp, IoSettingsOutline } from "react-icons/io5";
import { IoMdHome } from "react-icons/io";
import { IoPeople } from "react-icons/io5";
import { HiLocationMarker } from "react-icons/hi";
import General from "views/admin/settings/general";
import VehicleTypeList from "views/admin/settings/vehicleTypeList";
import RideView from "views/admin/rides/rideview";
// import BreakPoints from "views/admin/settings/BreakPoints/breakPoints";
import BreakPointsForm from "views/admin/settings/breakPointForm";
import BreakingPoints from "views/admin/settings/BreakPoints";
import Dashboard from "views/admin/dashboard/Dashboard";

 
const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "dashboard",
    icon: <IoMdHome className="h-6 w-6" />,
    component: <Dashboard />,
  },
  {
    name: "Spots",
    layout: "/admin",
    path: "default",
    icon: <HiLocationMarker className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Staff",
    layout: "/admin",
    path: "staff",
    icon: <IoPeople className="h-6 w-6" />,
    component: <Staff />
  },
  {
    name: "Vehicles",
    layout: "/admin",
    path: "vehicles",
    icon: <IoCarSportSharp className="h-6 w-6" />,
    component: <Vehicles />,
  },
  {
    name: "Rides",
    layout: "/admin",
    path: "rides",
    icon: <GiMountainRoad className="h-6 w-6" />,
    component: <Rides />,
  },
  {
    name: "Settings",
    layout: "/admin",
    path: "settings/general",
    component: <General/>,
    icon: <IoSettingsOutline className="h-6 w-6" />,
    // secondary: true,
  },
  {
    name: "Settings",
    layout: "/admin",
    path: "settings/breakpoints",
    component: <BreakingPoints/>,
    icon: <IoSettingsOutline className="h-6 w-6" />,
    secondary: true,
  },
  // {
  //   name: "Settings",
  //   layout: "/admin",
  //   path: "settings",
  //   icon: <IoSettingsOutline className="h-6 w-6" />,
  //   component: <BreakingPoints />,
  //   // secondary: true,
  // },
  {
    name: "Spot Form",
    layout: "/admin",
    path: "default/spot/spot-form",
    // icon: <MdPerson className="h-6 w-6" />,
    component: <SpotForm />,
    secondary: true,
  },
  // {
  //   name: "Drivers",
  //   layout: "/admin",
  //   icon: <MdBarChart className="h-6 w-6" />,
  //   path: "data-tables",
  //   component: <DataTables />,
  // },
  {
    name: "Vehicles",
    layout: "/admin",
    path: "vehicles/vehicle-form",
    icon: <MdPerson className="h-6 w-6" />,
    component: <VehicleForm />,
    secondary:true
  },
  {
    name: "Staff-From",
    layout: "/admin",
    path: "staff/staff-form",
    icon: <MdPerson className="h-6 w-6" />,
    component: <StaffForm/>,
    secondary:true
  },
  {
    name: "Ride Details",
    layout: "/admin",
    path: "rides/ridedetails/:id",
    // icon: <MdPerson className="h-6 w-6" />,
    component: <RideView />,
    secondary: true,
  },
  {
    name: "BreakPoints-Form",
    layout: "/admin",
    path: "settings/breakpoint-form",
    icon: <MdPerson className="h-6 w-6" />,
    component: <BreakPointsForm/>,
    secondary:true
  },
  {
    name: "BreakPoints-Form ID",
    layout: "/admin",
    path: "settings/breakpoint-form/:id",
    icon: <MdPerson className="h-6 w-6" />,
    component: <BreakPointsForm/>,
    secondary:true
  },
  // {
  //   name: "Sign In",
  //   layout: "/auth",
  //   path: "sign-in",
  //   icon: <MdLock className="h-6 w-6" />,
  //   component: <SignIn />,
  // },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "rtl",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <RTLDefault />,
  // },
];
export default routes;
