import { MapContainer, TileLayer, Marker, Popup, Rectangle, Map, FeatureGroup, Circle, useMap } from 'react-leaflet';
import L, { bounds } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import React, { useEffect, useState, ReactNode } from "react";
// import '../map.css';

import LocationPin from '../../../assets/svg/LocationPin.svg'
import { useNavigate } from 'react-router-dom';
import { getActiveSpots } from 'services/customAPI';
import Loader from 'components/loader/loader';

const icon = L.icon({
  html: `<div className="custom-marker"><span>1</span></div>`,
  // iconUrl: LocationPin,
  className: 'custom-icon',
  iconSize: [40, 40]
})


const ResetCenterView = (props) => {
  const { position } = props;
  const map = useMap()

  useEffect(() => {
    if (position) {
      map.setView(
        L.latLng(position[0], position[1]),
        map.getZoom(),
        {
          animate: true
        }
      )
    }
  }, [position])

  return null;
}


function Dashboard(props) {

  const navigate = useNavigate();
  const [loading,setLoading] = useState();
  const [position, setPosition] = useState([19.07, 72.87]);
  const [spotdata, setSpotData] = useState([]);
  // const [data, setData] = useState([]); 

  // const parser = new DOMParser();

  const customIcon = (number) => {
    return L.divIcon({
      html: `<div class="custom-marker" style="position: relative;">
                   <img src="${LocationPin}" class="icon-image" style="width: 100%; height: auto;">
                   <span class="number-overlay">${number}</span>
                 </div>`,
      className: 'custom-icon',
      iconSize: [30, 30], // Adjust icon size as needed
    });
  };

  // async function convertToUsableSpotArray(spotArray) {

  //   const res = Promise.all(
  //     spotArray.map(async (spot) => {

  //       return {
  //         // spotName: spot?.spotName,
  //         bounds:spot?.bounds,
  //       };
  //     })
  //   );

  //   return res;
  // }



  const getData = async () => {
    try {
      setLoading(true)
      // console.log("get data for dashboard------------")
      const response = await getActiveSpots();
      // console.log("res---------------------", response)
      setSpotData(response);
      setLoading(false)

    } catch (error) {
      setLoading(false)
      console.log(`get-all-rides >> error :>> `, error);
    }
  };

  useEffect(() => {
    getData();
  }, []);



  useEffect(() => {
    // console.log("po", position)
  }, [position])




  // useEffect(() => {
  //   setData([spotdata]);
  // }, [spotdata]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (<>
        <div className="w-full pb-0 p-4 bg-white rounded-lg pt-0 pe-0 h-[100vh] mt-5 mb-5 grid grid-cols-12 gap-4">
          {/* Header */}
          <header className="relative flex items-center justify-between col-span-12 pt-5">
            <div className="text-xl font-bold text-navy-700 dark:text-white">
              Active Spots
            </div>
          </header>

          {/* Right side - Map container */}
          <div className="col-span-12 overflow-hidden pr-4 w-1/8 h-1/8">
            <div className="w-full h-full">
              <MapContainer center={position} zoom={12} className="z-10">
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />

                {spotdata.map((marker, i) => {

                  const bounds = marker;

                  if (bounds[0]?.lat == undefined) {
                    return
                  }
                  const centerPoint = [bounds[0]?.lat, bounds[0]?.lng];

                  return (
                    <Marker key={i} position={centerPoint} icon={customIcon(i + 1)}>
                      <Popup>
                        <div className="text-center">{marker.spotName}</div>
                      </Popup>
                    </Marker>
                  )
                })}
                <ResetCenterView position={position} />
              </MapContainer>
            </div>
          </div>

        </div>
      </>)}
    </>
  )
}

export default Dashboard;
