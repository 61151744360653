import React, { useEffect, useState } from "react";
import Card from "components/card";
import deleteIcon from "../../../../assets/svg/deleteIcon.svg";
import ButtonEdit from "../../../../assets/svg/ButtonEdit.svg";
import block from "../../../../assets/svg/block.svg";
import unblock from "../../../../assets/svg/unblock.svg";
import "./ColumnsTable.css";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { Navigate, useNavigate } from "react-router-dom";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";



function BreakPoints(props) {
  const { tableData, handleClickForDeleteModal, handleToggleForStatusMOdal,handleUpdate } =
    props;
  console.log("tableData from index.js", tableData)
  const [sorting, setSorting] = React.useState([]);
  const navigate = useNavigate();


  const columns = [
   columnHelper.accessor("breakingPointName", {
          id: "breakingPointName",
          header: () => (
            <p className="text-sm font-bold text-gray-600 dark:text-white">
              Breaking Points Name
            </p>
          ),
          cell: (info) => (
            <p className="text-sm font-bold text-navy-700 dark:text-white ">
              {info.getValue()}
            </p>
          ),
        }),
        columnHelper.accessor("sqeuenceNo", {
          id: " sqeuenceNo",
          header: () => (
            <p className="text-sm font-bold text-gray-600 dark:text-white">
              Sequence No.
            </p>
          ),
          cell: (info) => (
            <p className="text-sm font-bold text-navy-700 dark:text-white">
              {info.getValue()}
            </p>
          ),
        }),

    columnHelper.accessor("action", {
      id: "action",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Action
        </p>
      ),
      cell: (info) => {
        console.log("info",info.row.original.action.id)
        return(
        <div className="flex items-center">
          <div className="cursor-pointer">
            <img
              src={ButtonEdit}
              style={{ marginRight: "8px" }}
              onClick={() => handleUpdate(info.row.original.action.id)}
            />
          </div>
          <div className="cursor-pointer">
            <img
              src={deleteIcon}
              onClick={() => handleClickForDeleteModal(info.row.original)}
            />
          </div>
        </div>)
      },
    }),
  ]; // eslint-disable-next-line
  const [data, setData] = React.useState([...tableData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  useEffect(() => {
    setData([...tableData]);
  }, [tableData]);

  // useEffect(() => { }, []);

  return (
    <Card extra={"w-full pb-10 p-4 h-full"}>
      <header className="relative flex items-center justify-between">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          BreakingPoints
        </div>
        <div>
          <button
            className="my-sm-0 add-driver-button my-2 ms-1 bg-brand-500 dark:bg-brand-400"
            type="submit"
            style={{ width: '120px' }}
            onClick={() => navigate("/admin/settings/breakpoint-form")}
          >
            Add BreakPoints
          </button>
        </div>
        {/* <CardMenu /> */}
      </header>

      <div className="mt-4 overflow-x-scroll xl:overflow-x-hidden">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="!border-px !border-gray-400">
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                      className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                    >
                      <div className="flex gap-4 text-xs text-gray-200 text-left">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {
                          <>
                            {header.column.getIsSorted() === "asc" ? (
                              <FaCaretUp className="mr-[-6] text-gray-600 font-bold" size={20} />
                            ) : header.column.getIsSorted() === "desc" ? (
                              <FaCaretDown size={20} className="text-gray-600 font-bold" />
                            ) : (
                              <div className="flex mr-[-6]">
                                <FaCaretDown size={20} className="text-gray-600 font-bold" />
                              </div>
                            )}
                          </>
                        }
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          {tableData.length == 0 ? (
            <tbody>
              <tr>
                <td colSpan={columns.length} style={{ textAlign: "center" }}>
                  <h2 className="m-4" style={{ fontSize: "30px" }}>
                    No Results!
                  </h2>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {table
                .getRowModel()
                .rows?.slice(0, 10)
                .map((row) => {
                  // console.log("object row :>> ", row);
                  return (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td
                            key={cell.id}
                            className="min-w-[135px] border-white/0 py-3 pr-4 text-start"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          )}
        </table>
      </div>
    </Card>
  );
}

export default BreakPoints;
const columnHelper = createColumnHelper();

