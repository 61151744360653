import customAxios from "./appservices";

// BreakingPoints -----------------
export const createBreakPointApi = (data) => {
  console.log("api called : " ,data)
  return customAxios.post("/create-break-points", data)
};

export const getBreakingPoints = (data) => {
  console.log("getBreakingPoints--------------called")
  return customAxios.get(`/get-breaking-points?page=${data.page}&limit=${data.limit}`);
};

export const updateBreakPointApi = (id, data) => {
  console.log("updateBreakPointApi called",id,data)
  return customAxios.patch(`/update-break-points/${id}`, data);
};

export const getBreakPointById = (id) => {
  console.log("getBreakPointById called",id)
  return customAxios.get(`/get-break-point-id/${id}`)
}

export const deleteBreakPoints = (id) => {
  // console.log("deleteBreakPoints")
  return customAxios.delete(`/delete-breakingPoints/${id}`);
};


// Spot----------

export const createSpot = (data) => {
    console.log("data",data)
    return customAxios.post("/create-spot", data);
  };

  export const getSpotsList = (data) => {
    console.log("getDataApi")
    return customAxios.get(`/get-spot-list?page=${data.page}&limit=${data.limit}`);
  };

  export const getSpotsListVehicle = () => {
    console.log("getDataApiVehiclespot")
    return customAxios.get(`/get-spot-list-vehicle`);
  };

  export const deleteSpot = (id) => {
    console.log("deleteSpotApi")
    return customAxios.delete(`/delete-spot/${id}`);
  };

  export const getActiveSpots = () => {
    return customAxios.get(`/get-active-spot`)
  }

  // vehicle Type

  export const handleCreateVehicleApi = (id, data) => {
    return customAxios.patch(`/updateVehicle/${id}`, data);
  };
  
  export const createVehicleApi = (data) => {
    return customAxios.post("/create-vehicle", data);
  };
  
  export const getVehicleByIdApi = (id) => {
    return customAxios.get(`/getVehicleById/${id}`);
  };

  export const deleteVehicleApi = (id) => {
    return customAxios.delete(`/deleteVehicle/${id}`);
  };

  export const getPaginatedVehicleDataApi = (data) => {
    return customAxios.get(
      `/paginatedVehicleData?page=${data.page}&limit=${data.limit}`
    );
  };
  

  export const getS3SignUrlApi = (data, headers) => {
    return customAxios.post(`/presignedurl`, data, headers);
  };

  export const searchVehiclesApi = (data) => {
    return customAxios.get(
      `/search-vehicles?page=${data.page}&limit=${data.limit}&query=${data.query}`
    );
  };

  export const getVehicleTypeList = () => {
    return customAxios.get("/get-vehicle-type");
  };

  export const deleteObjectFromS3Api = (data) => {
    return customAxios.post("/delete-object-from-s3", data);
  };


  // staff 
  export const deleteStaffHandleApi = (id) => {
    return customAxios.delete(`/deleteStaff/${id}`);
  };
  
  export const getPaginatedStaffDataApi = (data) => {
    return customAxios.get(
      `/paginatedStaffData?page=${data.page}&limit=${data.limit}`
    );
  };
  
  export const updateDriverStatusApi = (id) => {
    return customAxios.patch(`/update-driver-status/${id}`);
  };
  
  export const searchDriverApi = (data) => {
    return customAxios.post(`/search-drivers`, data);
  };

  export const getDriverByIdApi = (id) => {
    console.log("Params1>>", id);
    return customAxios.get(`/getDriverById/${id}`);
  };

  export const createStaffApi = (data) => {
    return customAxios.post("/create-staff", data);
  };

  export const handleCreateDriverApi = (id, data) => {
    return customAxios.patch(`/updateDriver/${id}`, data);
  };
  
  export const getAvailableVehiclesApi = () => {
    return customAxios.get("/allAvailableVehicles");
  };

  export const searchDriversApi = (data) => {
    return customAxios.get(
      `/search-drivers?page=${data.page}&limit=${data.limit}&query=${data.query}`
    );
  };

  //Rides API

  export const getAllRidesApi = (data) => {
    return customAxios.get(
      `/get-all-rides?page=${data.page}&limit=${data.limit}`
    );
  };
  
  export const getCurrentRidesApi = (data) => {
    return customAxios.get(
      `/get-current-rides?page=${data.page}&limit=${data.limit}`
    );
  };
  
  export const getRidesByFilterApi = (data) => {
    return customAxios.get(
      `/get-rides-by-filter?page=${data.page}&limit=${data.limit}&filter=${data.filter}`
    );
  };
  
  export const searchRidesApi = (data) => {
    return customAxios.get(
      `/search-ride?page=${data.page}&limit=${data.limit}&query=${data.query}`
    );
  };

  export const getRideDetailsApi = (id) => {
    return customAxios.get(`/get-ride-details/${id}`);
  };
  

  // App Image and Name
  export const getAppNameAndImage = () => {
    return customAxios.get(`/get-app`);
  };
  
  export const handleCreateAppNameAndImageApi = (id, data) => {
    return customAxios.patch(`/update-app/${id}`, data);
  };
  
  export const createAppNameAndImageApi = (data) => {
    return customAxios.post("/create-app", data);
  };

  export const deleteVehicleType = (id) => {
    return customAxios.delete(`/delete-vehicle-type/${id}`);
  };

// admin login 
export const handleLoginApi = (data) => {
  return customAxios.post("/admin-login", data);
};

export const handleRegisterApi = (data) => {
  return customAxios.post("/admin-register", data);
};

export const handleChangePasswordApi = (data) => {
  return customAxios.post("/change-password", data);
};
