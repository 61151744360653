export const statusOptions = [
    { value: "Driver", label: "Driver" },
    { value: "Helper", label: "Helper" },
    { value: "Paramedics", label: "Paramedics" },
  ];

  export const rideStatusOptions = [
    { value: "all", label: "All" },
    { value: "base-out", label: "Base Out" },
    { value: "hospitalised", label: "Hospitalised" },
    { value: "base-in", label: "Base In" },
    { value: "completed", label: "Completed" },
  ];
  
  export const vehicleType = [
    { value: '', label: 'Select a Type', disabled: true},
    { value: 'HatchBack', label: 'HatchBack' },
    { value: 'Sedan', label: 'Sedan' },
    { value: 'SUV', label: 'SUV' },
  ]