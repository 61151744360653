import React, { useRef, useState } from "react";
import Card from "../../../../components/card";
import Select from "react-select";
import { Button } from "@chakra-ui/react";
import "./staffform.css";
import { statusOptions } from "../../../../utils/constants";
import {  Formik, useFormikContext } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  createStaffApi,
  getAvailableVehiclesApi,
  getDriverByIdApi,
  handleCreateDriverApi,
} from "../../../../services/customAPI";
import Loader from "components/loader/loader";
import { toast } from "react-toastify";

const Logger = (props) => {
  const {
    allAvailableVehicles,
  } = props;
  const firstRender = useRef(true);
  const formik = useFormikContext();

  React.useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      if (formik.values?.vehicleNumber) {
        allAvailableVehicles.map((data) => {
          if (data.vehicleNumber === formik.values.vehicleNumber) {

          }
        });
      } else {
      }
    }
  }, [formik.values?.vehicleNumber]);
  return null;
};


const StaffForm = () => {
  const [paramData, setParamData] = useState({});
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [staffType, setStaffType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDriver, setIsDriver] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    vehicleNumber: "",
    staffType: "",
  });
  const [allAvailableVehicles, setAllAvailableVehicles] = useState([]);
  const [options, setOptions] = useState([
    {
      value: "",
      label: "",
    },
  ]);

  const navigate = useNavigate();
  const params = useParams();

  const driverSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Name must be atleast two characters.")
      .required("Name is required"),
    // lastName: Yup.string()
    //   .min(2, "Last name must be atleast two characters.")
    //   .required("Last name is required"),
    mobileNumber: Yup.string()
      .matches(/^[0-9]+$/, "Invalid mobile number.")
      .min(10, "Mobile Number must be 10 digits only.")
      .max(10, "Mobile Number must be 10 digits only.")
      .required("Mobile number is Required"),
    staffType: Yup.string().required("Staff type is required"),
    // .nullable()
  });

  const successToast = (message) => {
    // console.log("Inside successToast", message); // Add this line for debugging
    toast.success(`${message}`, {
      position: 'top-right',
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      style: { borderRadius: "15px" },
    });
  };

  const errorToast = (message) => {
    toast.error(`${message}`, {
      position: 'top-right',
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      style: { borderRadius: "15px" },
    });
  };

  const getAvailableVehicles = async () => {
    try {
      const res = await getAvailableVehiclesApi();
      if (!res) {
        errorToast("Vehicles not available");
      }
      setOptions(
        res.data.map((option) => {
          return {
            value: option.vehicleNumber,
            label: option.vehicleNumber,
          };
        })
      );
      setAllAvailableVehicles(res.data);
    } catch (error) {
      errorToast(error.response.data.message);
    }
  };


  const handleCreateDriver = async (values) => {
    setIsLoading(true);
    try {
      if (params.id) {

        const result = await handleCreateDriverApi(params.id, {
          firstName: values.firstName,
          lastName: values.lastName,
          mobileNumber: values.mobileNumber,
          vehicleNumber: values.vehicleNumber,
          staffType: values.staffType,
        });

        if (result.message) {
          successToast("Staff Updated Successfully");
          navigate("/admin/drivers");
          setIsLoading(false);
        } else {
          errorToast("Something went wrong");
        }
      } else {

        const result = await createStaffApi({
          firstName: values.firstName,
          lastName: values.lastName,
          mobileNumber: values.mobileNumber,
          vehicleNumber: values.vehicleNumber,
          staffType: values.staffType,
        });

        if (result.message) {
          successToast("Staff Created Successfully");
          navigate("/admin/staff");
          setIsLoading(false);
        } else {
          errorToast("Something went wrong");
        }
      }
    } catch (error) {
      errorToast(error.response.data.message);
      console.log(error);
      setIsLoading(false);
    }
  };

  const getData = async (id) => {
    console.log("get data called :>> ");
    setIsLoading(true);
    try {
      const res = await getDriverByIdApi(id);

      setInitialFormValues({
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        mobileNumber: res.data.mobileNumber,
        vehicleNumber: res.data.vehicleNumber,
        staffType: res.data.staffType,
      });

      setParamData(res.data);
      setVehicleNumber(res.data.vehicleNumber);
      setStaffType(res.data.vehicleType);

      setIsLoading(false);
    } catch (error) {
      errorToast(error.response.data.message);
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (options && paramData?.vehicleNumber) {
      if (options[options.length - 1]?.value !== paramData.vehicleNumber) {
        options.push({
          value: paramData?.vehicleNumber,
          label: paramData?.vehicleNumber,
        });
        setOptions(options);
        allAvailableVehicles.push(paramData);
      }
    }
  }, [options, paramData]);

  React.useEffect(() => {
    if (params.id) {
      getData(params.id);
    }
  }, [params]);

  React.useEffect(() => {
    getAvailableVehicles();
  }, []);

  return (
    <>
      {/* <Navbar flag={false} brandText="driverform" /> */}

      {isLoading ? (
        <Loader />
      ) : (
        <Card extra={"w-full pb-6 p-4 mt-4 pt-10"}>
          <header className="relative flex items-center justify-between ps-20">
            {params.id ? (
              <div className="text-xl font-bold text-navy-700 dark:text-white">
                Edit Staff
              </div>
            ) : (
              <div className="text-xl font-bold text-navy-700 dark:text-white">
                Add Staff
              </div>
            )}
          </header>
          <div className="p-10 pb-5 pe-20 ps-20">
            <Formik
              enableReinitialize={true}
              initialValues={initialFormValues}
              onSubmit={(values) => handleCreateDriver(values)}
              validationSchema={driverSchema}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Logger
                    display="hidden"
                    allAvailableVehicles={allAvailableVehicles}
                  />

                  <div className="flex justify-between">
                    <div className="mb-3 me-6 w-full">
                      <label
                        htmlFor="firstName"
                        className="input-custom-label dark:text-white"
                      >
                        Name
                      </label>
                      <input
                        required
                        className="mt-2 h-12 w-full rounded-xl border bg-white/0 p-3 text-sm outline-none"
                        name="firstName"
                        type="text"
                        id="firstName"
                        placeholder="Enter your name here"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.firstName}
                      />
                      <div className="error-input">
                        {errors.firstName && touched.firstName
                          ? errors.firstName
                          : null}
                      </div>
                    </div>
                    {/* <div className="mb-3 ms-6 w-full">
                      <label
                        htmlFor="lastName"
                        className="input-custom-label dark:text-white"
                      >
                        Last Name
                      </label>
                      <input
                        required
                        className="mt-2 h-12 w-full rounded-xl border bg-white/0 p-3 text-sm outline-none"
                        name="lastName"
                        type="text"
                        id="lastName"
                        placeholder="Enter last name here"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.lastName}
                      />
                      <div className="error-input">
                        {errors.lastName && touched.lastName
                          ? errors.lastName
                          : null}
                      </div>
                    </div> */}
                    {/* <div className="mb-3 ms-6 w-full">
                    </div> */}
                  </div>

                  <div className="flex justify-between">
                    <div className="mb-3 me-6 w-full">
                      <label
                        htmlFor="mobileNumber"
                        className="input-custom-label dark:text-white"
                      >
                        Mobile Number
                      </label>
                      <input
                        required
                        className="mt-2 h-12 w-full rounded-xl border bg-white/0 p-3 text-sm outline-none"
                        name="mobileNumber"
                        type="number"
                        id="mobileNumber"
                        placeholder="Enter mobile number here"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.mobileNumber}
                      />
                      <div className="error-input">
                        {errors.mobileNumber && touched.mobileNumber
                          ? errors.mobileNumber
                          : null}
                      </div>
                    </div>
                    <div className="mb-3 ms-6 w-full">
                      <label
                        htmlFor="staffType"
                        className="input-custom-label dark:text-white"
                      >
                        Staff Type
                      </label>
                      <Select
                        options={statusOptions}
                        id="staffType"
                        name="staffType"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setStaffType(e.value);
                          if(e.value === 'Driver'){
                            setIsDriver(true)
                          }else{
                            setIsDriver(false)
                          }
                          values.staffType = e.value;
                        }}
                        value={statusOptions.filter(function (option) {
                          return option.value == staffType;
                        })}
                        styles={{
                          // Fixes the overlapping problem of the component
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 9999,
                          }),
                          control: (provided) => ({
                            ...provided,
                            height: "47px", // Adjust the height as needed
                            marginTop: "8px",
                            borderRadius: "10px",
                            fontSize: "0.875rem",
                            borderColor: "#e6e6e6",
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                              ? "#f2f3f7"
                              : "white", // Change the background color here
                            color: "black", // Change the text color here
                            "&:hover": {
                              backgroundColor: "#f2f3f7", // Change the background color on hover
                            },
                          }),
                        }}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                      <div className="error-input">
                        {errors.staffType && touched.staffType
                          ? errors.staffType
                          : null}
                      </div>
                    </div>
                  </div>

                  { isDriver && <div className="flex justify-between">
                    <div className="mb-3 me-6 w-full">
                      <label
                        htmlFor="vehicleNumber"
                        className="input-custom-label dark:text-white"
                      >
                        Vehicle Number
                      </label>
                      <Select
                        options={options}
                        id="vehicleNumber"
                        name="vehicleNumber"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setVehicleNumber(e.value);
                          values.vehicleNumber = e.value;
                        }}
                        value={options.filter(function (option) {
                          return option.value == vehicleNumber;
                        })}
                        styles={{
                          // Fixes the overlapping problem of the component
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 9999,
                          }),
                          control: (provided) => ({
                            ...provided,
                            height: "47px", // Adjust the height as needed
                            marginTop: "8px",
                            borderRadius: "10px",
                            fontSize: "0.875rem",
                            borderColor: "#e6e6e6",
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                              ? "#f2f3f7"
                              : "white", // Change the background color here
                            color: "black", // Change the text color here
                            "&:hover": {
                              backgroundColor: "#f2f3f7", // Change the background color on hover
                            },
                          }),
                        }}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                      <div className="error-input">
                        {errors.vehicleNumber && touched.vehicleNumber
                          ? errors.vehicleNumber
                          : null}
                      </div>
                    </div>
                    <div className="mb-3 ms-6 w-full">
                    </div>
                  </div>}

                  <div className="button-save-cancel mt-3 flex justify-end">
                    <Button
                      className=" cancel-button my-2 ms-1 sm:my-0"
                      onClick={() => navigate("/admin/staff")}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      className="save-button my-2 ms-1 bg-brand-500 dark:bg-brand-400 sm:my-0"
                    >
                      Save
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Card>
      )}
    </>
  );
};

export default StaffForm;
