import React from "react";
import { useEffect, useState, FC } from "react";
import L, { Icon } from 'leaflet'
import { MapContainer, TileLayer, Marker, Popup, Rectangle, Map, FeatureGroup, Circle, useMap, Polyline } from 'react-leaflet';
import { useParams } from "react-router-dom";
import Navbar from "../../../../components/navbar";
import Loader from "../../../../components/loader/loader";
import Card from "../../../../components/card";
import time from "../../../../assets/svg/time.svg";
import date from "../../../../assets/svg/date.svg";
import ridePickDest from "../../../../assets/svg/ridePickDest.svg";
import call from "../../../../assets/svg/call.svg";
import dummyCar from "../../../../assets/svg/dummyCar.svg";
import dummyProfile from "../../../../assets/svg/dummyProfile.svg";
import LocationPin from '../../../../assets/svg/LocationPin.svg'
import "./rideview.css";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  // getDriverLocationApi,
  getRideDetailsApi,
  getS3SignUrlApi,
} from "services/customAPI";


const icon = L.icon({
  iconUrl: LocationPin,
  iconSize: [40, 40]
})
const center = { lat: 19.118830203528184, lng: 72.88509654051545 };

const ImageWithFallback = ({ src, alt, fallbackSrc }) => {
  const handleImageError = (event) => {
    // This function is called when the original image fails to load.
    // Set the source (src) of the image to the fallback source.
    event.currentTarget.src = fallbackSrc;
  };

  return (
    <img
      src={src}
      alt={alt}
      onError={handleImageError}
      width={70}
      height={70}
    />
  );
};

const RideView = () => {
  const params = useParams();
  const [ride, setRide] = useState(null);
  const [path, setPath] = useState([
    [19.07, 72.87],
  ]);
  const [updateStatus, setUpdateStatus] = useState([]);
  const [updatestatus, setUpdatestatus] = useState([]);
  const [realPath, setRealPath] = useState([19.07, 72.87]);
  const [driverLocation, setDriverLocation] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dateTimeCreatedAt, setDateTimeCreatedAt] = useState("");
  const [driverImagePath, setDriverImagePath] = useState("");
  const [vehicleImagePath, setVehicleImagePath] = useState("");
  const [isLoaded, setIsLoaded] = useState(true);
  const [position, setPosition] = useState([19.07, 72.87]);
  const polylinePositions = [
    [51.505, -0.09], // Example coordinates
    [51.51, -0.1],   // Example coordinates
    [51.52, -0.08]   // Example coordinates
  ];

  // const { isLoaded } = useJsApiLoader({
  //   id: "google-map-script",
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  //   // googleMapsApiKey: "",
  // });

  // if (!isLoaded) {
  //   console.log("not loading");
  // }

  const customIcon = (number) => {
    return L.divIcon({
      html: `<div class="custom-marker" style="position: relative;">
                 <img src="${LocationPin}" class="icon-image" style="width: 100%; height: auto;">
                 <span class="number-overlay">${number}</span>
               </div>`,
      className: 'custom-icon',
      iconSize: [30, 30], // Adjust icon size as needed
    });

  };

  const convertPath = (coords) => {
    const path = coords?.map((ele) => {
      if (ele && ele.latitude && ele.longitude) {
        return [ele.latitude, ele.longitude, ele?.label];
      } else {
        // Handle cases where latitude or longitude is null or undefined
        return null; // or any other appropriate value
      }
    });
    // console.log(`convertPath >> path :>> `, path);
    return path;
  };


  // const getDriverLocation = async (id) => {
  //   console.log(`getDriverLocationcalled`);
  //   // console.log('rideDriverId', rideDriverId)
  //   const response = await getDriverLocationApi({
  //     driverId: id,
  //   });
  //   console.log("getDriverLocation >> response :>> ", response);
  //   const driverCoords = {
  //     lat: response.data.latitude,
  //     lng: response.data.longitude,
  //   };
  //   setDriverLocation(driverCoords);
  // };

  function convertUtcToIst(utcTimeStr) {
    const utcDate = new Date(utcTimeStr);
    const istOffsetMinutes = 330; // IST offset in minutes (UTC+5:30)

    const istTime = new Date(utcDate.getTime() + istOffsetMinutes * 60 * 1000);
    return istTime.toISOString(); // Return in ISO format
  }

  async function getS3SignUrl(key, contentType, type) {
    const headers = { "Content-Type": "application/json" };
    if (key) {
      const response = await getS3SignUrlApi(
        {
          key,
          contentType,
          type,
        },
        { headers }
      );
      // console.log("getS3SignUrl", response?.url);
      return response?.url;
    }
  }

  const getDriverImage = async (keys) => {
    // const key = keys;
    const contentType = "image/*";
    const type = "get";
    const data = await getS3SignUrl(keys, contentType, type);
    // console.log("data :>> ", data);
    setDriverImagePath(data);
  };

  const getVehicleImage = async (keys) => {
    // const key = keys;
    const contentType = "image/*";
    const type = "get";
    const data = await getS3SignUrl(keys, contentType, type);
    // console.log("data :>> ", data);
    setVehicleImagePath(data);
  };

  const getData = async () => {
    setIsLoading(true);
    try {
      // console.log("params.id", params.id)
      const res = await getRideDetailsApi(params.id);
      // console.log("log while setting driverid", res);

      // getDriverImage(res.data[0]?.driverDetails[0]?.profileImageKey);
      // getVehicleImage(res.data[0]?.vehicleDetails[0]?.profileImageKey);
      setRide(res?.data[0]);
      // console.log("shad", res?.data[0]?.driverRidePath)
      if (res?.data[0]?.driverRidePath && res.data[0].driverRidePath.length > 0) {
        // console.log("hiiiii")
        setPath(convertPath(res?.data[0]?.driverRidePath));
      }
      // setRealPath(convertPath(res?.data[0].realPath));
      setUpdateStatus(res?.data[0].statusUpdates)
      setDateTimeCreatedAt(convertUtcToIst(res?.data[0].createdAt));

      // console.log("updateStatus", updateStatus)
      // setRideDriverId(res.data[0].driverId)
      if (res?.data[0]?.driverId) {
        // getDriverLocation(res?.data[0]?.driverId);
      }
      setIsLoading(false);
    } catch (error) {
      // errorToast(error.response.data.message)
      setIsLoading(false);
    }
  };

  // Assuming 'path' is an array of coordinates
  const focusOnPath = (map, path) => {
    if (path.length > 0) {
      const bounds = L.latLngBounds(path);
      map.fitBounds(bounds);
    }
  };

  const calculateCenter = (start, end) => {
    const lat = (start[0] + end[0]) / 2;
    const lng = (start[1] + end[1]) / 2;
    return [lat, lng];
  };

  useEffect(() => {

    const updatedStatus = updateStatus !== 'undefined' ? updateStatus?.map(statusUpdate => {
      return {
        status: statusUpdate.status,
        time: convertUtcToIst(statusUpdate.time).substring(11, 16)
        // You can add other fields here if needed
      };
    }) : [];

    setUpdatestatus(updatedStatus)

    // setUpdateStatus(updatedStatus);

    // console.log("updateStatusfff", updatestatus)
  }, [updateStatus])

  useEffect(() => {
    getData();
    // getImage()
  }, []);

  return (
    <>
      {/* <Navbar flag={false} brandText="Ride details" /> */}
      <Link
        to="/admin/rides"
        className="flex items-center space-x-2 text-gray-600 hover:text-gray-900"
      >
        <FaArrowLeft />
        <div>Back</div>
      </Link>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Card
            extra={
              "w-full pb-0 p-4 pt-0 pe-0 h-[610px] mt-5 mb-5 grid grid-cols-12 gap-4"
            }
          >
            <div className="col-span-5">
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  paddingBottom: "10px",
                  paddingTop: "14px",
                }}
              >
                Ride Details
              </div>
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  // paddingBottom: "18px",
                }}
              >
                <img
                  src={date}
                  width={16}
                  height={16}
                  style={{ display: "inline-block", marginRight: "2px" }}
                />
                <span className="pe-5">
                  {" "}
                  {dateTimeCreatedAt?.substring(0, 10)}
                </span>
                {/* <img
                  src={time}
                  width={16}
                  height={16}
                  style={{ display: "inline-block", marginRight: "2px" }}
                />
                <span className="pe-5">
                  {" "}
                  {dateTimeCreatedAt?.substring(11, 16)}
                </span>
                <span
                  className='ongoingClass'
                >
                  base-out
                </span> */}
              </div>
              <div className="p-2 pb-3">
                {/* <div className="col-span-1">
                  <img src={ridePickDest} width={20} height={88} />
                </div>
                <div
                  className="col-span-7"
                  style={{ fontSize: "12px", fontWeight: "400", width: "70%" }}
                >
                  <div style={{ paddingBottom: "30px" }}>
                    {/* {ride?.pickUpAddress} */}
                {/* </div> */}
                {/* <div>{ride?.dropAddress}</div> */}
                {/* </div> */}
                <div style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  paddingBottom: "18px",
                  gap: '3px',
                }}>
                  {/* <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                    <img
                      src={time}
                      width={16}
                      height={16}
                      style={{ marginRight: "2px" }}
                      alt="Time Icon"
                    />
                    <span>
                      {dateTimeCreatedAt?.substring(11, 16)}
                    </span>
                    <span
                      className='ongoingClass'
                      style={{ marginLeft: "6px" }}
                    >
                      base-out
                    </span>
                  </div> */}
                  {updatestatus !== 'undefined' && updatestatus?.map((update, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '6px', marginTop: '8px' }}>
                      <img
                        src={time}
                        width={16}
                        height={16}
                        style={{ marginRight: "2px" }}
                        alt="Time Icon"
                      />
                      <span>{update.time}</span>
                      <span
                        className={
                          index === updatestatus.length-1
                            ? "completedClass"
                            : update.status === "cancelled"
                              ? "cancelledClass"
                              : "ongoingClass"
                        }
                      >
                        {update.status}
                      </span>
                    </div>
                  ))}
                </div>

              </div>
              <div className="m-1 w-1/4 rounded-[4px] p-3 dark:bg-white">
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#464E5F",
                  }}
                >
                  Fare:{" "}
                </span>
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "#212121",
                  }}
                >
                  &#8377;{ride?.fare}
                </span>
              </div>
              <hr style={{ color: "#E1E2F1" }} />
              <div
                className="pt-3"
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  paddingBottom: "8px",
                }}
              >
                Driver
              </div>
              <div className="grid grid-cols-8 gap-2 pb-3">
                <div className="col-span-2">
                  {/* {profileImagePath !== "" ? (
                    <img
                      src={profileImagePath}
                      width={70}
                      height={70}
                      alt="profile image"
                      onerror="this.onerror=null;img/noimg.jpg;"
                    />
                  ) : (
                    <img
                      src={dummyProfileImage}
                      width={70}
                      height={70}
                      alt="profile image"
                    />
                  )} */}
                  {driverImagePath ? (
                    <ImageWithFallback
                      src={driverImagePath}
                      alt={"profile image"}
                      fallbackSrc={dummyProfile}
                    />
                  ) : (
                    <img src={dummyProfile} width={70} height={70} />
                  )}
                </div>
                <div className="col-span-6">
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      paddingBottom: "10px",
                    }}
                  >
                    {ride?.driverDetails[0]
                      ? ride?.driverDetails[0]?.firstName +
                      " " +
                      ride?.driverDetails[0]?.lastName
                      : "NA"}
                  </div>
                  <div>
                    <img
                      src={call}
                      width={16}
                      height={16}
                      style={{ display: "inline-block", marginRight: "2px" }}
                    />{" "}
                    <span style={{ fontSize: "12px", fontWeight: "600" }}>
                      {ride?.driverDetails[0]?.mobileNumber
                        ? ride?.driverDetails[0]?.mobileNumber
                        : "NA"}
                    </span>
                  </div>
                </div>
              </div>
              <hr style={{ color: "#E1E2F1" }} />
              <div
                className="pt-3"
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  paddingBottom: "8px",
                }}
              >
                Vehicle
              </div>
              <div className="grid grid-cols-8 gap-2 pb-3">
                <div className="col-span-2">
                  {vehicleImagePath ? (
                    <ImageWithFallback
                      src={vehicleImagePath}
                      alt={"profile image"}
                      fallbackSrc={dummyCar}
                    />
                  ) : (
                    <img src={dummyCar} width={60} height={60} />
                  )}
                  {/* <img src={dummyCar} width={70} height={70} /> */}
                </div>
                <div className="col-span-6">
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      paddingBottom: "10px",
                    }}
                  >
                    {/* {ride?.driverDetails[0]?.vehicleName
                      ? ride?.driverDetails[0]?.vehicleName
                      : "NA"} */}
                  </div>
                  <div>
                    <span style={{ fontSize: "12px", fontWeight: "600" }}>
                      {ride?.driverDetails[0]?.vehicleNumber
                        ? `${ride?.driverDetails[0]?.vehicleNumber?.substring(
                          0,
                          2
                        ) || ""
                        } ${ride?.driverDetails[0]?.vehicleNumber?.substring(
                          2,
                          4
                        ) || ""
                        } ${ride?.driverDetails[0]?.vehicleNumber?.substring(
                          4,
                          6
                        ) || ""
                        } ${ride?.driverDetails[0]?.vehicleNumber?.substring(
                          6,
                          10
                        ) || ""
                        }`
                        : "NA"}
                    </span>
                  </div>
                </div>
              </div>
              <hr style={{ color: "#E1E2F1" }} />
              {/* <div
                className="pt-3"
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  paddingBottom: "8px",
                }}
              >
                Rider
              </div>
              <div className="grid grid-cols-8 gap-2 pb-3">
                <div className="col-span-2">
                  <img src={dummyProfile} width={70} height={70} />
                </div>
                <div className="col-span-6">
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      paddingBottom: "10px",
                    }}
                  >
                    {ride?.riderDetails[0]?.name
                      ? ride?.riderDetails[0]?.name
                      : "NA"}
                  </div>
                  <div>
                    <img
                      src={call}
                      width={16}
                      height={16}
                      style={{ display: "inline-block", marginRight: "2px" }}
                    />{" "}
                    <span style={{ fontSize: "12px", fontWeight: "600" }}>
                      {ride?.riderDetails[0]?.mobileNumber
                        ? ride?.riderDetails[0]?.mobileNumber
                        : "NA"}
                    </span>
                  </div>
                </div>
              </div> */}
            </div>

            <div className="col-span-7">
              {!isLoaded ? (
                <h1>Loading...</h1>
              ) : (
                // <GoogleMap
                //   mapContainerStyle={{
                //     width: "100%",
                //     height: "650px",
                //     borderTopRightRadius: "10px",
                //     borderBottomRightRadius: "10px",
                //   }}
                //   center={center}
                //   zoom={10}
                // >
                //   <Marker
                //     position={{
                //       lat: ride?.pickUpLocation[0],
                //       lng: ride?.pickUpLocation[1],
                //     }}
                //     // icon={car}
                //     label="P"
                //   />

                //   <Marker
                //     position={{
                //       lat: ride?.dropLocation[0],
                //       lng: ride?.dropLocation[1],
                //     }}
                //     // icon={car}
                //     label="D"
                //   />

                //   {driverLocation && (
                //     <Marker
                //       position={driverLocation}
                //       // icon={car}
                //       label="DR"
                //     />
                //   )}

                //   <Polyline
                //     path={path}
                //     options={{
                //       strokeWeight: 4,
                //     }}
                //   />

                //   <Polyline
                //     path={realPath}
                //     // strokeWidth={4}
                //     options={{ strokeColor: "red", strokeWeight: 4 }}
                //   />
                // </GoogleMap>
                <MapContainer
                  center={path.length > 0 ? calculateCenter(path[0], path[path.length - 1]) : [0, 0]}
                  zoom={14}
                  className="z-10 leaflet-container1"
                  mapContainerStyle={{
                    width: "100%",
                    height: "50%",
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                  }}
                  whenCreated={(map) => focusOnPath(map, path)}
                >
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                  <Marker position={[path[0][0] + 0.0001, path[0][1]]} icon={customIcon('S')}>
                    <Popup>This is Starting Point</Popup>
                  </Marker>
                  {
                    path.map((point, index) => {
                      if (typeof point[2] !== 'undefined') {
                        const iconValue = point[2].charAt(0).toUpperCase();
                        return (
                          <Marker key={index} position={[point[0] - 0.000022, point[1]]} icon={customIcon(iconValue)}>
                            <Popup>This is a location</Popup>
                          </Marker>
                        );
                      } else {
                        return null;
                      }
                    })
                  }
                  <Marker position={[path[path.length - 1][0], path[path.length - 1][1]]} icon={customIcon('E')}>
                    <Popup>This is Ending Point</Popup>
                  </Marker>
                  <Polyline positions={path} options={{ weight: 100 }} />
                  {/* <ResetCenterView position={position} /> */}
                </MapContainer>
              )}
            </div>
            {/* </div> */}
          </Card>
        </>
      )}
    </>
  );
};

export default RideView;
